import React from "react";
import { Box, Text, Button } from "rebass/styled-components";
import { ContactForm } from "gatsby-theme-jules";
import { Avatar } from "gatsby-theme-jules";

/** This is a wrapper around the contact form which adds some styling, a call to action and image of a person to be used as a lead generation form */
const LeadForm = (pageLocation, promoDetails) => {
  return (
    <Box
      variant="leadBox"
      textAlign="center"
      sx={{
        position: "sticky",
        top: 10,
      }}
    >
      {/* <Box width={["100%", "150px"]} m="auto" mb={3}>
        <Avatar rounded />
      </Box> */}
      <Text as="h3" mb={3} variant="subHeading" fontSize={[6]}>
        Home loan answers in 48 hours
      </Text>
      <Text as="p" fontSize={[2]}>
        <a
          href="https://the-capilitan-group.GetTrail.com/Account/Register/7f1fab4a-c3da-46b3-8df5-9ac3c2e894aa"
          target="_blank"
          rel="noopener"
        >
          <Button
            mt={3}
            variant="primary"
            minWidth={["100%", "100%", 0]}
            bg="#333"
          >
            Apply now
          </Button>
        </a>
      </Text>
      {/* <ContactForm formName="Lead form" /> */}
    </Box>
  );
};

export default LeadForm;
