// declare variables here

export default {
  id: "base",
  colors: {
    white: "#FFFEFB",
    black: "#00403d",
    text: "#718096",
    primary: "#38B2AC",
    primaryDark: "#2C7A7B",
    primaryDarkest: "#234E52",
    primaryLight: "#B2F5EA",
    lightGrey: "#F7FAFC",
    darkGrey: "#5f5e5b",
    secondary: "#fff",
    link: "#252526",
  },
  shadows: {
    default: "0px 2px 4px rgba(0,0,0,0.18)",
    card: "0 0 4px rgba(0, 0, 0, .125)",
  },
  fonts: {
    mainFont: '"Roboto", sans-serif',
    heading: '"Roboto", sans-serif',
    subheading: '"Roboto", sans-serif',
  },
  fontSizes: [
    "0.6667rem",
    "0.7778rem",
    "0.8889rem",
    "1rem", // 18px base font - #4
    "1.111rem", // 20px - #5
    "1.333rem", // 24px - #6
    "1.778rem", // 32px - #7
    "2.3rem", // 48px - #8
    "3.556rem", // 64px - #9
    "5.333rem", // 96px - #10
  ],
  fontWeights: [300, 400, 500, 600, 700],
  sizes: {
    maxWidth: "1100px",
  },
  borders: {
    borderWidths: "1px",
  },
  borderWidths: {
    default: "1px",
  },
  borderStyles: {
    default: "solid",
  },
  radii: {
    default: 0,
    round: 15,
  },
  styles: {
    shadow: "9px 8px 7px -2px rgba(227,224,227,0.84)",
    linkDecoration: "underline",
    // linkDecorationColor
  },
  space: [0, 4, 8, 16, 32, 64],
  breakpoints: ["40em", "52em", "64em"],
  text: {
    bigHeading: {
      textTransform: "uppercase",
    },
    subHeading: {
      fontWeight: 2,
      fontFamily: "subheading",
      textTransform: "uppercase",
    },
  },
  buttons: {
    primary: {
      bg: "#333",
      color: "white",
      cursor: "pointer",
      borderRadius: 0,
      fontWeight: 800,
      "&:hover": {
        bg: "primaryDark",
      },
      textTransform: "uppercase",
      // fontWeight: "500"
    },
    ghost: {
      borderRadius: 0,
      background: "white",
      color: "black",
      cursor: "pointer",
      boxShadow: "default",
      "&:hover": {
        bg: "primary",
      },
      textTransform: "uppercase",
      // fontWeight: "500"
    },

    secondary: {
      color: "black",
      backgroundColor: "secondary",
    },
  },
  variants: {
    leadBox: {
      p: 4,
      color: "white",
      bg: "primary",
      borderRadius: "default",
      borderWidth: 3,
      borderColor: "white",
      borderStyle: "default",
      boxShadow: "none",
      button: { bg: "#333", color: "white", borderRadius: 0, fontWeight: 800 },
      h3: {
        textTransform: "uppercase",
        color: "white",
        fontSize: 6,
      },
    },
    tinted: {
      bg: "lightGrey",
    },
    card: {
      a: { color: "text", textDecoration: "none" },
      transition: "transform .2s",
      bg: "lightGrey",
      "&:hover": {
        transform: "scale(1.05)",
      },
      flex: "1 1 300px",
      m: 2,
      h2: {
        textTransform: "capitalize",
        color: "black",
      },
      img: {
        borderRadius: "10px 0 10px",
      },
    },
  },
};
