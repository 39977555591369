import React from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import { Box } from "rebass/styled-components";
import Img from "gatsby-image";
const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "flamingoLogo" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <Box px={3} mx="auto" width={150} sx={{ zIndex: 2 }}>
      <Link to="/">
        <Img
          fluid={data.file.childImageSharp.fluid}
          alt="Flamingo Financial Insurance and Mortgage Brokers Wellington"
        />
      </Link>
    </Box>
  );
};

export default Logo;
